import React, { useRef, useEffect } from 'react';
import createGlobe from 'cobe'

const Globe = () => {
    const canvasRef = useRef();
    const globeDimension = 750;
    const devicePixelRatio = 2;

    useEffect(() => {
        let phi = 0;

        const globe = createGlobe(canvasRef.current, {
            devicePixelRatio: devicePixelRatio,
            width: globeDimension * devicePixelRatio,
            height: globeDimension * devicePixelRatio,
            phi: 0,
            theta: 0,
            dark: 0.6,
            diffuse: 1.2,
            mapSamples: 100000,
            mapBrightness: 6,
            baseColor: [21/256, 56/256, 150/256],
            markerColor: [0.1, 0.8, 1],
            glowColor: [169/256, 182/256, 217/256],
            markers: [
                // longitude latitude
                { location: [37.7595, -122.4367], size: 0.03 },
                { location: [40.7128, -74.006], size: 0.1 }
            ],
            onRender: (state) => {
                // Called on every animation frame.
                // `state` will be an empty object, return updated params.
                state.phi = phi;
                phi += 0.001;
            }
        });

        return () => {
            globe.destroy();
        };
    }, []);


    return (
        <canvas ref={canvasRef} style={{ width: globeDimension, height: globeDimension }} />
    );
}

export default Globe;
