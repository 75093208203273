import React from 'react';
import LeftContainer from './Components/LeftContainer';
import RightContainer from './Components/RightContainer';

const Home = () => {
    return (
        <div style={styles.container}>
            <div style={styles.halfContainer}>
                <LeftContainer />
            </div>
            <div style={styles.halfContainer}>
                <RightContainer />
            </div>
        </div>
    );
};

const styles = {
    container: {
        width: '100%',
        height: '100%',
        padding: '25px 50px',
        boxSizing: 'border-box',
        display: 'flex',
        color: '#fff',
        fontSize: '25px',
    },
    halfContainer: {
        width: '50%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
};

export default Home;
