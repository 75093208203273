import React from 'react';
import Globe from './Globe';

const LeftContainer = () => {
    return (
        <>
            <div
                style={{
                    ...styles.rowContainer,
                    height: '75px',
                    alignItems: 'center',
                }}>
                Warming
            </div>
            <div
                style={{
                    ...styles.rowContainer,
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                }}>
                <Globe />
            </div>
            <div
                style={{
                    ...styles.rowContainer,
                    height: '75px',
                    alignItems: 'center',
                    fontSize: '20px',
                }}>
                💙 @raymondhechen
            </div>
        </>
    );
};

const styles = {
    rowContainer: {
        width: '100%',
        display: 'flex',
    },
};

export default LeftContainer;
