import React from 'react';
import Card from './Card';
import CardGrid from './CardGrid';

const RightContainer = () => {
    return (
        <>
            <div
                style={{
                    ...styles.rowContainer,
                    height: '75px',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    fontSize: '17.5px',
                    fontWeight: '500',
                }}>
                <div style={styles.rowElement}>Temperature</div>
                <div style={styles.rowElement}>Precipitation</div>
                <div style={styles.rowElement}>Air Qualtiy</div>
            </div>
            <div
                style={{
                    ...styles.scrollContainer,
                }}>
                <CardGrid>
                    {Array.apply(null, Array(20)).map((_) => (
                        <Card />
                    ))}
                </CardGrid>
            </div>
        </>
    );
};

const styles = {
    rowContainer: {
        width: '100%',
        display: 'flex',
    },
    scrollContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        overflowY: 'auto',
        scrollbarWidth: "none",
    },
    rowElement: {
        margin: '0 20px',
    },
};

export default RightContainer;
