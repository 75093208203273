import React from 'react';

const Card = (props) => {
    return <div style={styles.container}>{props.children}</div>;
};

const styles = {
    container: {
        width: '175px',
        height: "200px",
        borderRadius: "6px",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: "white",
    },
};

export default Card;
