import React from 'react';

const CardGrid = (props) => {
    return <div style={styles.container}>{props.children}</div>;
};

const styles = {
    container: {
        width: "100%",
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(175px, 1fr))',
        gridGap: "25px",
        justifyContent: 'space-between',
        overflowY: 'auto',
        scrollbarWidth: "none",
    },
};

export default CardGrid;
