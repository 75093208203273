import React from 'react';
import Home from './Home';

const App = () => {
    return (
        <div style={styles.container}>
            <Home />
        </div>
    );
};

const styles = {
    container: {
        width: '100vw',
        height: '100vh',
        background:
        'linear-gradient(70deg, rgba(29,56,91,0.15) 12%, rgba(24,82,156,0.15) 35%, rgba(76,61,166,0.15) 62%, rgba(89,44,146,0.15) 75%, rgba(113,12,110,0.15) 95%)',
        backgroundColor: "rgb(0,0,0)",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
};

export default App;
